//============================================
// Formular
//============================================

// Bullet Elements
let formData = {
	slide: 0,
	acitvatedSlide: 0,
	isForMe: true,
	isWearing: true,
	bullets: [],
	timer: null,

	updateProgressBar: () => {
		const length = Math.round(100 / (formData.length)) + Math.round(100 / (formData.length) * (formData.slide))
		if (document.getElementById('progress')) {
			document.querySelector('#progress > div').style.width = length + '%'
		}
		if (document.getElementById('progress__number')) {
			document.getElementById('progress__number').innerHTML = formData.slide + 1
		}
	},

	bulletNavigation: (q) => {
		const questions = document.querySelectorAll(q)
		for (let i = 0; i < questions.length; i++) {
			formData.bullets.push({ id: i, active: i <= formData.slide, activated: i <= formData.activated, disabled: false })
		}
		formData.length = questions.length
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	},

	updateBulletNavigation: () => {
		const bulletContainer = document.getElementById('bullets') ? document.getElementById('bullets') : false
		if (bulletContainer) {
			bulletContainer.innerHTML = ""
			formData.bullets.map(bullet => {
				bullet.id <= formData.slide ? bullet.active = true : bullet.active = false
				bullet.id <= formData.acitvatedSlide ? bullet.activated = true : bullet.activated = false
				bulletContainer.innerHTML += `<div class="bullet${bullet.disabled ? ' disabled' : ''}${bullet.active ? ' active' : ''}${bullet.activated ? ' activated' : ''}" ${formData.acitvatedSlide >= bullet.id && !bullet.disabled ? 'onclick="getSlide(' + bullet.id + ')"' : ''}></div>`
			})

		}
	},

	disableBulletClick: (id) => {
		formData.bullets[id].disabled = true
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	},

	enableBulletClick: (id) => {
		formData.bullets[id].disabled = false
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	},

	idleFunction: (el) => {
		const rndInt = Math.floor(Math.random() * (8 - 1) + 1)
		const num = rndInt > 1 ? 'n' : ''
		decreaseCounter('.counter', 'amp__hoergeraete_counter')
		el.innerHTML = `Letzte Anfrage vor <strong>${rndInt} Minute${num}</strong>`;
		formData.idle = true;
	},

	setInteractionTimer: (id) => {
		const container = document.getElementById(id)
		container.innerHTML = "";
		formData.timer = setTimeout(() => formData.idleFunction(container), 10000)
	},

	clearInteractionTimer: () => {
		clearTimeout(formData.timer)
	}

}

// if (document.querySelectorAll('[name="client"]').length > 0) { 

// }
if (document.getElementById("form")) {
	const userAgent = document.getElementById('userAgent')
	const client = document.querySelectorAll('[name="client"]')
	const studie = document.querySelectorAll('[name="studie"]')
	const form = document.getElementById("form");
	const questions = form.querySelectorAll(".question");
	const cbWer = form.querySelectorAll('[name="wer"]');
	const cbPKV = form.querySelectorAll('[name="pkv"]');
	const cbHg = form.querySelectorAll('[name="hoergeraetetraeger"]');
	const cbProbleme = form.querySelectorAll('[name="sprachverstehen"]') ? form.querySelectorAll('[name="sprachverstehen"]') : null
	const cbAlterHG = form.querySelectorAll('[name="alterhoergeraete"]');
	const btnAlter = form.querySelector('[data-name="alter"]') ? form.querySelector('[data-name="alter"]') : false;
	const btnPlz = form.querySelector('[data-name="plz"]');
	const btnCode = form.querySelector('[data-name="code"]') ? form.querySelector('[data-name="code"]') : false;
	const btnTel = form.querySelector('[data-name="tel"]');
	const btnName = form.querySelector('[data-name="name"]');
	const btnMail = form.querySelector("#next__submit");
	const route1 = form.querySelectorAll('.route_1')
	const route2 = form.querySelectorAll('.route_2')

	//Inputs
	const alter = document.getElementById('alter') ? document.getElementById('alter') : false
	const codeField = document.getElementById('code') ? document.getElementById('code') : ''
	const plz = document.getElementById('plz')
	const tel = document.getElementById('tel')
	const vorname = document.getElementById('vorname')
	const nachname = document.getElementById('name')
	const mail = document.getElementById('mail')

	userAgent.value = window.navigator.appCodeName + " " + window.navigator.appVersion

	const questionArray = [];

	questions.forEach(q => questionArray.push(q));


	const getSlide = (slide) => {
		formData.slide = slide
		questionArray.forEach(q => q.classList.remove('active'))
		questionArray[slide].classList.add('active')
		formData.updateBulletNavigation()
		formData.updateProgressBar()

	}

	questionArray[formData.slide].classList.add("active");

	const showQuestion = i => {
		formData.slide = i
		questions.forEach(q => {
			q.style.left = formData.slide * -100 + '%'
			q.classList.remove('active')
		})
		questions[formData.slide].classList.add('active')
		const inputs = questions[formData.slide].querySelectorAll('input')
		inputs.forEach(i => {
			i.tabIndex = 1
		})
		formData.updateBulletNavigation()
		formData.updateProgressBar()

	}

	const nextQuestion = () => {
		formData.slide++;
		formData.slide > formData.acitvatedSlide ? formData.acitvatedSlide++ : null
		questions.forEach(q => {
			q.style.left = formData.slide * -100 + '%'
			q.classList.remove('active')
		})
		questions[formData.slide].classList.add('active')
		const inputs = questions[formData.slide].querySelectorAll('input')
		inputs.forEach(i => {
			i.tabIndex = 1
		})
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	};

	const isRadioChecked = name => {
		const radioGroup = form.querySelectorAll("[name=" + name + "]");
		const checkedArray = []
		radioGroup.forEach(r => {
			if (r.checked) {
				checkedArray.push(r)
			}
			if (checkedArray.length > 0) {
				return true
			} else {
				return false
			}
		})
	};

	const checkCharLength = (el, err, val, msg) => {
		const input = document.getElementById(el).value;
		const error = document.getElementById(err);
		if (input.length < val) {
			error.classList.add("alert");
			error.innerHTML = msg;
		} else {
			error.classList.remove("alert");
			error.innerHTML = "";
			nextQuestion();
		}
	};
	const checkStringLength = (el, err, val, msg) => {
		const input = document.getElementById(el).value;
		const error = document.getElementById(err);

		if (/\d/.test(input) === false) {
			if (input.length < val) {
				error.classList.add("alert");
				error.innerHTML = msg;
				return false
			} else {
				error.classList.remove("alert");
				error.innerHTML = "";
				return true
			}

		} else {
			error.classList.add("alert");
			error.innerHTML = msg;

		}
	};

	const checkStringLengthInput = (el, val) => {
		const string = document.getElementById(el).value;
		const input = document.getElementById(el)
		if (/\d/.test(string) === false && string.length >= val) {
			input.parentNode.classList.add('success')
		} else {
			input.parentNode.classList.remove('success')
		}



	};


	const isValidString = (el, val) => {
		const input = document.getElementById(el).value;
		if (/\d/.test(input) === true || input.length < val) {
			return false
		} else {
			return true
		}
	};

	const checkHoerzuCode = (el) => {
		const input = document.getElementById(el).value
		let isValid = false
		const error = document.getElementById('alertCode')
		const allowedValues = ["hören 21", "hören21", "hoeren 21", "hoeren21"]
		allowedValues.map(v => {
			if (input.toLowerCase() === v) {
				isValid = true
			}
		})
		if (isValid) {
			nextQuestion()
		} else {
			error.classList.add('alert')
			error.innerHTML = 'Aktionscode ungültig'
		}

	}


	/* =============================
	 * Kundenvalidierung
	============================= */


	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	const alertNoClient = () => {
		const modal = document.getElementById('form--modal')
		modal.classList.add('active')
		setCookie('amp_client', 'true', 90)
	}
	const noClientsAllowed = (el) => {
		el.disabled = true
		localStorage.setItem('amp_pb_client', 'yes');
		alertNoClient()
	}
	const noClient = () => {
		localStorage.removeItem('amp_pb_client');
		document.getElementById('client01').disabled = false
		const modal = document.getElementById('form--modal')
		modal.classList.remove('active')
	}

	const closeFormModal = () => {
		const modal = document.getElementById('form--modal')
		modal.classList.remove('active')
	}



	/* =============================
	 * Telefonvalidierung
	============================= */
	const checkTel = (el, err) => {
		const tel = document.getElementById(el).value;
		const error = document.getElementById(err);

		const check = /^[0-9()+/– -]+$/;
		if (check.test(tel) === false || tel.replace(/[^0-9]/g, "").length < 4) {
			error.classList.add("alert");
			error.innerHTML = "Bitte gültige Telefonnummer eingeben!";
			return false
		} else {
			error.classList.remove("alert");
			error.innerHTML = "";
			return true
		}
	};

	const awaitPhoneApiCall = async (tel) => {
		const response = await fetch("https://api.numlookupapi.com/v1/validate/" + tel + "?apikey=oANq7eAHJmyvLBA9QDaTQgVtjrTcHasaYmErS6rk&country_code=DE", { method: 'GET', redirect: 'follow' })
		const data = await response.json()
		return data
	}

	const checkTelApi = (el, err) => {

		const tel = document.getElementById(el).value;
		const error = document.getElementById(err);
		let isValid = false
		const input = document.getElementById(el)

			


		awaitPhoneApiCall(tel)
			.then(data => {
				if (data.valid === true) {
					error.classList.remove("alert");
					error.innerHTML = "";
					if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) alertNoClient()
					input.parentNode.classList.add('success')
					nextQuestion()
					formData.idle ? null : formData.setInteractionTimer('timeout--notice')

					console.log('Tracking Telefon');
					if (Cookiebot && Cookiebot.consent.marketing) {
						ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 6')
					}
					
				} else {
					error.classList.add("alert");
					error.innerHTML = "Bitte gültige Telefonnummer eingeben (ohne Ländervorwahl, keine Sonderzeichen)!";
					input.parentNode.classList.remove('success')
					return isValid = false
				}
			})
			.catch(err => {
				error.classList.add("alert");
				error.innerHTML = "Bitte gültige Telefonnummer eingeben (ohne Ländervorwahl, keine Sonderzeichen)!";
				return isValid = false
			})

		// var xhr = new XMLHttpRequest();
		// xhr.withCredentials = true;

		// xhr.addEventListener("readystatechange", function() {
		// 	if (this.readyState === 4) {
		// 		const response = JSON.parse(this.responseText)

		// 		if (response.valid) {
		// 			isValid = true
		// 			error.classList.remove("alert");
		// 			error.innerHTML = "";
		// 		} else {
		// 			isValid = false
		// 			error.classList.add("alert");
		// 			error.innerHTML = "Bitte gültige Telefonnummer eingeben (ohne Ländervorwahl, keine Sonderzeichen)!";
		// 		}

		// }
		// });

		// xhr.open("GET", "https://api.numlookupapi.com/v1/validate/"+tel+"?apikey=oANq7eAHJmyvLBA9QDaTQgVtjrTcHasaYmErS6rk&country_code=DE");

		// xhr.send();



		// fetch("https://api.numlookupapi.com/v1/validate/"+ tel +"?apikey=oANq7eAHJmyvLBA9QDaTQgVtjrTcHasaYmErS6rk&country_code=DE", { method: 'GET', redirect: 'follow'})
		// 	.then(response => response.json())
		// 	.then(result => {
		// 		console.log(result.valid);
		// 		if (result.valid) {
		// 			error.classList.remove("alert");
		// 			error.innerHTML = "";
		// 			return isValid = true

		// 		} else { 
		// 			error.classList.add("alert");
		// 			error.innerHTML = "Bitte gültige Telefonnummer eingeben (ohne Ländervorwahl, keine Sonderzeichen)!";
		// 			return isValid = false
		// 		}
		// 		})
		// 		.catch(err => {
		// 			error.classList.add("alert");
		// 			error.innerHTML = "Bitte gültige Telefonnummer eingeben (ohne Ländervorwahl, keine Sonderzeichen)!";
		// 			return isValid = false
		// 		});

		console.log('TELEFON: ', isValid)

		return isValid

	};
	const checkTelInput = (el) => {
		const tel = document.getElementById(el).value;
		if (tel.length > 1 && tel[0] != '0') {
			document.getElementById(el).value = '0' + tel		
			console.log('geändert')
		}
		const input = document.getElementById(el)
		const check = /^[0-9()+/– -]+$/;
		if (check.test(tel) && tel.replace(/[^0-9]/g, "").length > 3) {
			//input.parentNode.classList.add('success')
		} else {
			//input.parentNode.classList.remove('success')
		}
	};


	/* =============================
	 * Altersvalidierung
	============================= */
	const checkAge = (el, error) => {
		const age = document.getElementById(el).value;
		const err = document.getElementById(error);

		if (age < 1 || age > 125) {
			err.innerHTML = "Bitte gültiges Alter angeben";
			err.classList.add("alert");
			return false
		} else {
			err.classList.remove("alert");
			err.innerHTML = "";
			return true
		}
	};

	const checkAgeInput = (el) => {
		const age = document.getElementById(el).value;
		const input = document.getElementById(el)
		if (age > 0 && age < 126) {
			input.parentNode.classList.add('success')
		} else {
			input.parentNode.classList.remove('success')
		}
	};

	/* =============================
	 * Plz validierung
	============================= */
	const checkPlz = (el, error) => {
		const plz = document.getElementById(el).value;
		const err = document.getElementById(error);
		if (plz.length != 5) {
			err.classList.add("alert");
			err.innerHTML = "Bitte gültige Postleitzahl angeben";
			return false
		} else {
			err.classList.remove("alert");
			err.innerHTML = "";
			return true;
		}
	};

	const checkPlzInput = (el) => {
		const plz = document.getElementById(el).value;
		const input = document.getElementById(el)
		if (plz.length === 5) {
			input.parentNode.classList.add('success')
		} else {
			input.parentNode.classList.remove('success')
		}
	};

	/* =============================
	 * E-Mail Validierung
	============================= */
	const checkEmail = (el, error) => {
		const email = document.getElementById(el).value;
		const err = document.getElementById(error);
		const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (
			reg.test(email) == false
		) {
			err.classList.add("alert");
			err.innerHTML = "Bitte gültige E-Mail Adresse eingeben";
			return false
		} else {
			err.classList.remove("alert");
			err.innerHTML = "";
			return true
		}
	};

	const checkEmailInput = (el) => {
		const email = document.getElementById(el).value;
		const input = document.getElementById(el)
		//formData.clearInteractionTimer()
		const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (reg.test(email)) {
			input.parentNode.classList.add('success')
		} else {
			input.parentNode.classList.remove('success')
		}
		formData.idle ? null : formData.setInteractionTimer('timeout--notice')

	};


	const isCheckboxChecked = (el, error, msg) => {
		const checkbox = document.getElementById(el)
		const err = document.getElementById(error);
		if (checkbox.checked) {
			err.classList.remove("alert");
			err.innerHTML = "";
			return true
		} else {
			err.classList.add("alert");
			err.innerHTML = msg;
			return false
		}
	}

	function getValueFromRadioButton(name) {
		//Get all elements with the name
		var buttons = document.getElementsByName(name);
		for (var i = 0; i < buttons.length; i++) {
			//Check if button is checked
			var button = buttons[i];
			if (button.checked) {
				//Return value
				return button.value;
			}
		}
		//No radio button is selected.
		return null;
	}

	//============================================
	// Bereits Kunde
	//============================================
	client.forEach(c => {
		c.addEventListener('click', e => {
			if (c.checked) {
				document.querySelector('.question.active').style.opacity = '0.5'
				setTimeout(() => {
					document.querySelector('.question.active').style.opacity = '1'
					const ls = localStorage.getItem('amp_pb_client');

					if (ls) { return }

					nextQuestion()

				}, 800)

			}
		})
	})

	//============================================
	// Welche Kampagne
	//============================================
	studie.forEach(c => {
		c.addEventListener('click', e => {
			if (c.checked) {
				document.querySelector('.question.active').style.opacity = '0.5'
				setTimeout(() => {
					document.querySelector('.question.active').style.opacity = '1'
					const ls = localStorage.getItem('amp_pb_client');

					if (ls) { return }

					nextQuestion()

				}, 800)

			}
		})
	})

	//============================================
	// Für wen suchen Sie
	//============================================

	cbWer.forEach(cb => {
		cb.addEventListener('click', e => {

			if (document.getElementById('testplatz')) {
				//Testplatz entfernen
				document.getElementById('testplatz').style.display = 'none'
			}

			if (cb.checked) {
				if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
				document.querySelector('.question.active').style.opacity = '0.5'
				setTimeout(() => {
					document.querySelector('.question.active').style.opacity = '1'

					nextQuestion()

					//Route einblenden
					if (cb.value === "Für mich selbst") {
						route1.forEach(r => r.style.display = 'block')
						route2.forEach(r => r.style.display = 'none')
					} else {
						route2.forEach(r => r.style.display = 'block')
						route1.forEach(r => r.style.display = 'none')
					}

					//Adobe Tracking
					let inputValue
					switch (cb.value) {
						case "Für mich selbst":
							inputValue = "For myself";
							break;
						case "Für mein Kind":
							inputValue = "For my child";
							break;
						case "Für meine Eltern":
							inputValue = "For my parents";
							break;
						case "Für meine Großeltern":
							inputValue = "For my grandparents";
							break;
						case "Sonstige":
							inputValue = "Other";
							break;
					}
					window.digitalData.step_1 = inputValue;

					//Tracking, falls Cookie akzeptiert
					if (Cookiebot && Cookiebot.consent.marketing) {
						ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 1')
						_tfa.push({ notify: 'event', name: 'Kontaktformular Step 1', id: 1142431 })
						obApi('track', 'Kontaktformular Step 1')
						_satellite.track
					}

				}, 800)

			}
		})
	})


	//============================================
	// Krankenversicherung
	//============================================

	cbPKV.forEach(cb => {
		cb.addEventListener('click', e => {

			if (document.getElementById('testplatz')) {
				//Testplatz entfernen
				document.getElementById('testplatz').style.display = 'none'
			}

			if (cb.checked) {
				if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
				document.querySelector('.question.active').style.opacity = '0.5'
				setTimeout(() => {
					document.querySelector('.question.active').style.opacity = '1'

					nextQuestion()

					//Tracking, falls Cookie akzeptiert
					if (Cookiebot && Cookiebot.consent.marketing) {
						ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 1')
						_tfa.push({ notify: 'event', name: 'Kontaktformular Step 1', id: 1142431 })
						obApi('track', 'Kontaktformular Step 1')
						_satellite.track
					}

				}, 800)

			}
		})
	})




	//============================================
	// Hörgeräteträger
	//============================================

	cbHg.forEach(cb => {
		cb.addEventListener('click', e => {
			if (cb.checked) {
				if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
				document.querySelector('.question.active').style.opacity = '0.5'
				setTimeout(() => {
					document.querySelector('.question.active').style.opacity = '1'


					// Falls kein Högeräteträger nächsten Schritt überspringen
					if (cb.value === 'Nein') {
						nextQuestion()
						nextQuestion()
						formData.disableBulletClick(cbProbleme ? 3 : 2)
					} else {
						nextQuestion()
						formData.enableBulletClick(cbProbleme ? 3 : 2)
					}

					// //Adobe Tracking
					let inputValue
					switch (cb.value) {
						case "Ja":
							inputValue = "Yes";
							break;
						case "Nein":
							inputValue = "No";
							break;
					}
					window.digitalData.step_2 = inputValue;

					//Tracking, falls Cookie akzeptiert
					if (Cookiebot && Cookiebot.consent.marketing) {
						ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 2')
						_tfa.push({ notify: 'event', name: 'Kontaktformular Step 2', id: 1142431 })
						obApi('track', 'Kontaktformular Step 2')
						_satellite.track
					}
				}, 800)

			}

		})
	})

	//============================================
	// Sprachprobleme
	//============================================
	if (cbProbleme) {

		cbProbleme.forEach(cb => {
			cb.addEventListener('click', e => {
				console.log(e.target);
				if (cb.checked) {
					document.querySelector('.question.active').style.opacity = '0.5'
					setTimeout(() => {
						document.querySelector('.question.active').style.opacity = '1'


						if (cb.value === 'Nein') {
							nextQuestion()
							// formData.disableBulletClick(2)
						} else {
							nextQuestion()
							// formData.enableBulletClick(2)
						}

						// //Adobe Tracking
						let inputValue
						switch (cb.value) {
							case "Ja":
								inputValue = "Yes";
								break;
							case "Nein":
								inputValue = "No";
								break;
						}
						window.digitalData.step_1_2 = inputValue;

						//Tracking, falls Cookie akzeptiert
						if (Cookiebot && Cookiebot.consent.marketing) {
							ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 1.2')
							_tfa.push({ notify: 'event', name: 'Kontaktformular Step 1.2', id: 1142431 })
							obApi('track', 'Kontaktformular Step 1.2')
							_satellite.track
						}
					}, 800)

				}

			})
		})
	}

	//============================================
	// Alter Hörgeräte
	//============================================

	cbAlterHG.forEach(cb => {
		cb.addEventListener('click', e => {
			if (cb.checked) {
				if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }

				document.querySelector('.question.active').style.opacity = '0.5'

				setTimeout(() => {
					document.querySelector('.question.active').style.opacity = '1'

					nextQuestion()

					let inputValue
					switch (cb.value) {
						case "1 Jahr oder jünger":
							inputValue = "1 year or younger";
							break;
						case "2-4 Jahre":
							inputValue = "2-4 years";
							break;
						case "5 Jahre oder älter":
							inputValue = "5 years or more";
							break;
					}
					window.digitalData.step_3 = inputValue;


					//Tracking, falls Cookie akzeptiert
					if (Cookiebot && Cookiebot.consent.marketing) {
						ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 3')
						_tfa.push({ notify: 'event', name: 'Kontaktformular Step 3', id: 1142431 })
						obApi('track', 'Kontaktformular Step 3')
						_satellite.track
					}

				}, 800)
			}
		})
	})

	//============================================
	// Code
	//============================================

	if (btnCode) {

		btnCode.addEventListener("click", () => checkHoerzuCode('code'));

		// Alter onKeyup
		codeField.addEventListener('keypress', e => {
			e.key === 'Enter' && e.preventDefault()
			e.key === 'Enter' && checkHoerzuCode('code')
			e.key === 'Enter' && plz.focus()
		})
	}



	//============================================
	// Alter
	//============================================

	const validateAlter = () => {
		if (checkAge("alter", "alertAlter")) {
			if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
			nextQuestion()
			console.log('Tracking Alter');
			window.digitalData.step_5 = form.alter.value;
			if (Cookiebot && Cookiebot.consent.marketing) {
				ga('send', 'event', 'umfrage', 'click', 'Umfrage Step 4')
				_satellite.track;
			}
		}
	}
	if (btnAlter) btnAlter.addEventListener("click", validateAlter);
	if (alter) {
		// Alter onKeyup
		alter.addEventListener("keyup", () => checkAgeInput("alter"));
		alter.addEventListener("blur", () => checkAgeInput("alter"));
		alter.addEventListener('keypress', e => {
			e.key === 'Enter' && e.preventDefault()
			e.key === 'Enter' && validateAlter()
			e.key === 'Enter' && plz.focus()
		})

	}

	//============================================
	// PLZ
	//============================================

	const validatePLZ = () => {
		if (checkPlz("plz", "alertPlz") && plz.value.length > 0) {
			if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) alertNoClient()
			nextQuestion()
			window.digitalData.step_5 = form.plz.value;
			if (Cookiebot && Cookiebot.consent.marketing) {
				ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 5')
				_satellite.track;
			}
		}
	}

	btnPlz.addEventListener("click", validatePLZ);
	// PLZ onKeyup
	plz.addEventListener("keyup", () => checkPlzInput("plz"));
	plz.addEventListener("blur", () => checkPlzInput("plz"));
	plz.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && validatePLZ()
		e.key === 'Enter' && tel.focus()
	})


	//============================================
	// Telefon
	//============================================

	const validateTel = (e) => {
		if (checkTelApi("tel", "alertTel")) {
			const input = document.getElementById(el)

			input.parentNode.classList.add('success')

			if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) alertNoClient()
			nextQuestion()
			formData.idle ? null : formData.setInteractionTimer('timeout--notice')

			console.log('Tracking Telefon');
			if (Cookiebot && Cookiebot.consent.marketing) {
				ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 6')
			}

		}
	}

	btnTel.addEventListener("click", () => checkTelApi('tel', 'alertTel'));
	// Tel onKeyup
	tel.addEventListener("keyup", () => checkTelInput("tel"));
	tel.addEventListener("blur", () => checkTelInput("tel"));
	tel.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && checkTelApi('tel', 'alertTel')
		e.key === 'Enter' && vorname.focus()
	})


	//============================================
	// Name
	//============================================

	const validateName = () => {
		if (checkStringLength("name", "alertName", 2, "Bitte Namen angeben!")) {
			if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
			nextQuestion()
			console.log('Tracking Name');

			if (Cookiebot && Cookiebot.consent.marketing) {
				ga('send', 'event', 'umfrage', 'click', 'Kontaktformular Step 7')
			}
		}
		formData.idle ? null : formData.setInteractionTimer('timeout--notice')
	}

	btnName.addEventListener("click", validateName);

	// Name onKeyup
	nachname.addEventListener("keyup", () => checkStringLengthInput("name", 2));
	nachname.addEventListener("blur", () => checkStringLengthInput("name", 2));

	// Vorname onKeyup
	vorname.addEventListener("keyup", () => checkStringLengthInput("vorname", 2));
	vorname.addEventListener("blur", () => checkStringLengthInput("vorname", 2));

	nachname.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && validateName()
		e.key === 'Enter' && mail.focus()
	})
	vorname.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && nachname.focus()
	})


	//============================================
	// E-Mail
	//============================================


	const validateEmail = () => {
		//formData.clearInteractionTimer()

		if (checkEmail("mail", "alertEmail") &&
			isCheckboxChecked('check', 'alertEmail', 'Bitte Datenschutzbedingungen akzeptieren')) {
			if (document.getElementById('checksession')) {
				if (isCheckboxChecked('checksession', 'alertEmail', 'Bitte alle Felder akzeptieren')) {
					if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
					console.log('Tracking Senden');
					if (Cookiebot && Cookiebot.consent.marketing) {
						ga('send', 'event', 'umfrage', 'click', 'Umfrage senden')
						_tfa.push({ notify: 'event', name: 'Absenden', id: 1142431 });
						obApi('track', 'Absenden');
					}
					form.submit();
				}
			} else {
				console.log('Tracking Senden');
				if (Cookiebot && Cookiebot.consent.marketing) {
					ga('send', 'event', 'umfrage', 'click', 'Umfrage senden')
					_tfa.push({ notify: 'event', name: 'Absenden', id: 1142431 });
					obApi('track', 'Absenden');
				}
				form.submit();
			}
		}
	}

	btnMail.addEventListener("click", e => {
		e.preventDefault();
		if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
		validateEmail()
	});

	form.addEventListener('submit', e => {
		e.preventDefault();
		if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
		validateEmail();
	})

	mail.addEventListener('keyup', () => checkEmailInput('mail'))
	mail.addEventListener('blur', () => checkEmailInput('mail'))
	mail.addEventListener('keypress', e => e.key === 'Enter' && validateEmail())

	formData.bulletNavigation('.question');

}

const startForm = () => {
	const bullets = document.getElementById('bullets')
	const question = document.querySelector('.question')
	const introfrage = document.getElementById('introfrage')
	bullets.style.display = ''
	question.style.display = ''
	introfrage.style.display = 'none'
}