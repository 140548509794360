if (document.getElementById('dynamic__footer')) {
    const showDynamicFooter = () => { 
        const footer = document.getElementById('dynamic__footer');
        const footerPositionY = footer.offsetTop;
        const scrollTop = window.pageYOffset;
        console.log(scrollTop, document.body.scrollHeight / 3);
        if (scrollTop > document.body.scrollHeight / 3) {
            footer.classList.add('active')
        } else { 
            footer.classList.remove('active')
        }
    } 
    window.addEventListener('scroll', showDynamicFooter)
}


const scrollToAnchor = id => { 
    const el = document.getElementById(id)
    el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
    })
}