const openModal = (content, imageName) => { 
    const modal = document.createElement('div')
    const c = document.createElement('div')
    modal.id = "modal"
    c.className = 'modal--content'
    c.innerHTML = `<span class="close" onclick="closeModal()">&times;</span><div class="modal__text">${imageName ? `<img width="260" src="../assets/img/${imageName}.svg" alt="${imageName}">` : ''}<br>${content}</div>`

    modal.appendChild(c)
    document.body.appendChild(modal)
}

const closeModal = (e) => {
    const modal = document.getElementById('modal')
    if (modal) { 
        if (e.target.id === 'modal' || e.target.className === 'close' || e.key === 'Escape') { 
            document.body.removeChild(modal)
        }
    }
}
    
    window.addEventListener('click', closeModal)
window.addEventListener('keyup', closeModal)

const closeLightbox = (e) => {
    const modal = document.getElementById('lightbox')
    if (modal) { 
        if (e.target.id === 'lightbox' || e.target.className === 'close' || e.key === 'Escape') { 
            document.body.removeChild(modal)
        }
    }
}

const openLightbox = (content) => {
	
    const modal = document.createElement('div')
    const c = document.createElement('div')
    modal.id = "lightbox"
    c.className = 'modal--content lightbox'
    c.innerHTML = `<span class="close" onclick="closeLightbox(event)">&times;</span><div class="modal__text"><img src="${content}"></div>`

    modal.appendChild(c)
    document.body.appendChild(modal)
}


    
    window.addEventListener('click', closeLightbox)
window.addEventListener('keyup', closeLightbox)
	


//Loader Overlay
const openLoader = (text, extra) => {
	const loader = document.createElement('div')
	loader.id = "loader"
	loader.className = "loading"

	let kk = text ? `Wir prüfen, ob es für Versicherte der ${text} noch einen kostenlosen Testplatz gibt.` : 'Wir prüfen, ob es noch einen kostenlosen Testplatz gibt.'

	loader.innerHTML = `<h3>${kk}</h3><div class="spinner__loader"></div>`
	const searchParams = window.location.search ? window.location.search : ''
	const divider = !searchParams ? '?' : '&';
	const params = extra ? `${divider}krankenkasse=${extra}` : ''
	const krankenkasse = text ? `${divider}krankenkasse=${text}` : ''
	const search = searchParams + params + krankenkasse

	document.body.appendChild(loader)

	setTimeout(() => {
		window.location.href = `/krankenkasse/testplatz${search}`
	}, 2500)
 }