const hideOverlay = (el) => {
    const overlay = document.getElementById(el)
    overlay.classList.add('loaded')
}

const randomDuration = Math.round(Math.random() * (3000 - 1000 + 1) + 1000)

// if (document.getElementById('loader')) {
//     setTimeout(() => { 
//         hideOverlay('loader')
//     }, randomDuration)
// }